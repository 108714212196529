<script setup lang="ts">
import axios, { AxiosError, AxiosResponse } from 'axios';
import { onBeforeMount, onMounted, ref } from 'vue';
import { notifyUser } from '@/utils/helper';
import moment from 'moment';
import { useRouter } from 'vue-router';

/* interfaces */
import { tableInfo } from '@/interfaces/tableInfo';
import { nextMatch } from '@/interfaces/nextMatch';
import { Seats } from '@/interfaces/SeatData';

/** DATA */
const activeSeats = ref<number>(0);
const seats = ref<number>();
const all = ref<boolean>(false);
const allSeats = ref<Seats[]>([]);
const load = ref<boolean>(false);
const disable = ref<boolean>(false);
const router = useRouter();
const table = ref<number>();
const tableOpponent = ref<number>();
const teamImage = ref<string>();
const teamImageOpponent = ref<string>();
const opponentName = ref<string>();
const gameDate = ref<string>();

/** METHODS */

const getTable = async (): Promise<void> => {
  await axios
    .get('https://api.openligadb.de/getbltable/bl3/2024')
    .then((res: AxiosResponse): void => {
      const response: tableInfo[] = res.data;

      response.forEach((row: tableInfo, index: number): void => {
        if (row.teamName === 'SG Dynamo Dresden') {
          table.value = index + 1;
        }

        if (row.teamName === opponentName.value) {
          tableOpponent.value = index + 1;
        }
      });
    })
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser('Error', 'Error while retrieving match data', 'danger');
    });
};

const getNextMatch = async (): Promise<void> => {
  await axios
    .get('https://api.openligadb.de/getnextmatchbyleagueteam/4756/177')
    .then((res: AxiosResponse): void => {
      const response: nextMatch = res.data;
      //? because searchedHome could be team1 | team2 in response
      if (response.team1.shortName === process.env.HOME_TEAM) {
        teamImage.value = response.team1.teamIconUrl;
        opponentName.value = response.team2.teamName;
        teamImageOpponent.value = response.team2.teamIconUrl;
      } else {
        teamImage.value = response.team2.teamIconUrl;
        opponentName.value = response.team1.teamName;
        teamImageOpponent.value = response.team1.teamIconUrl;
      }
    })
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser('Error', 'Error while retrieving match data', 'danger');
    });
};

const switchAll = async (): Promise<void> => {
  load.value = true;
  disable.value = true;
  allSeats.value.forEach((seat) => {
    seat.switch = true;
    seat.disabled = true;
  });
  if (!all.value) {
    allSeats.value.forEach((seat) => {
      seat.switch = false;
      seat.disabled = false;
      seat.loading = false;
    });
  }
  const token = localStorage.getItem('access_token');
  await axios
    .post(
      '/api/dali/all',
      {
        switchable: all.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((): void => {
      load.value = false;
      disable.value = false;
      notifyUser('Successfully', 'Chairs successfully switched', 'success');
    })
    .catch((e: AxiosError): void => {
      load.value = false;
      disable.value = false;
      allSeats.value.forEach((seat) => {
        seat.switch = false;
        seat.disabled = false;
      });
      console.error(e);
      all.value = false;
      if (e.response?.status === 401) {
        router.push({ name: 'login' });
      } else {
        load.value = false;
        notifyUser(
          'Something went wrong',
          'Error while switch the chairs',
          'danger'
        );
      }
    });
};

const switchOne = async (seat: number): Promise<void> => {
  allSeats.value[seat]['loading'] = true;
  allSeats.value[seat]['disabled'] = true;
  activeSeats.value = 0;

  allSeats.value.forEach((seat) => {
    if (seat.switch) activeSeats.value++;
  });

  if (activeSeats.value === seats.value) all.value = true;
  if (all.value && !allSeats.value[seat]['switch']) {
    all.value = false;
  }

  const token = localStorage.getItem('access_token');
  await axios
    .post(
      `/api/dali/device`,
      {
        switchable: allSeats.value[seat]['switch'],
        deviceId: allSeats.value[seat]['seatId'],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser(
        'Something went wrong',
        'Error while switch the chair',
        'danger'
      );
      allSeats.value[seat]['loading'] = false;
      allSeats.value[seat]['disabled'] = false;
      allSeats.value[seat]['switch'] = false;
      if (all.value && !allSeats.value[seat]['switch']) {
        all.value = false;
      }
    });
};

onBeforeMount((): void => {
  seats.value = Number(process.env.VUE_APP_SEAT_AMOUNT);
  for (let i = 0; i < seats.value; i++) {
    allSeats.value.push({
      seatId: i + 1,
      name: 'Sitz ' + i + 1,
      loading: false,
      disabled: false,
      switch: false,
    });
  }
});

/* HOOKS */
onMounted(async (): Promise<void> => {
  await getNextMatch();
  await getTable();
});
</script>

<template>
  <div class="seat-heating-container">
    <VaCard class="card">
      <div class="header" style="justify-content: center">
        <h2>Sitzheizung aktivieren</h2>
      </div>
      <div class="stadium-image-container">
        <img
          class="stadium-image"
          src="@/assets/dresden.jpg"
          alt="Stadion Bild"
        />
        <div class="match-info-banner">
          <h6>Nächstes Spiel</h6>
          <div class="status-ticket-container">
            <div class="status-ticket">
              <div class="status-row">
                <img :src="teamImage" alt="dresden" />
                <div class="team-info">
                  <span>SG Dynamo Dresden</span>
                  <span>{{ table }}. in der 3. Liga</span>
                </div>
              </div>
              <div class="status-row-large">
                <span>VS</span>
              </div>
              <div class="status-row">
                <img :src="teamImageOpponent" :alt="opponentName" />
                <div class="team-info">
                  <span>{{ opponentName }}</span>
                  <span>{{ tableOpponent }}. in der 3. Liga</span>
                </div>
              </div>
            </div>
          </div>
          <p class="date">
            {{ moment().format('DD.MM.YYYY') }}
          </p>
        </div>
      </div>
      <div class="actions">
        <div class="all">
          <h2>Alle aktivieren</h2>
          <VaSwitch
            v-model="all"
            :loading="load"
            color="#F3AC26"
            :disabled="disable"
            size="small"
            @input="switchAll"
          />
        </div>
        <br />
        <div v-for="(seat, index) in allSeats" :key="index" class="single">
          <h2>Sitzplatz {{ index + 1 }} aktivieren</h2>
          <VaSwitch
            v-model="seat.switch"
            :loading="seat.loading"
            color="#F3AC26"
            :disabled="seat.disabled"
            size="small"
            @input="switchOne(index)"
          />
        </div>
      </div>
    </VaCard>
  </div>
</template>

<style lang="scss" scoped>
h6 {
  padding: 0;
  margin: 0;
}

.seat-heating-container {
  height: 100dvh;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  gap: 1rem;
  height: 95dvh;
  max-height: 95dvh;
}

.header {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.stadium-image-container {
  position: relative;
}

.stadium-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 25px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.match-info-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.75);
  padding: 12px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 85%;
  max-height: 85%;

  p {
    font-size: 14px;
  }
}

.status-ticket {
  padding-top: 5px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  color: white;
  border-radius: 12px;
  margin: 0;
  text-align: center;
  font-size: 14px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.ticket-top-line {
  position: absolute;
  display: flex;
  align-self: center;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: gray;
}

.status-row {
  color: black;
  font-weight: bold;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 0.75fr;
  align-items: center;
  justify-items: center;
  font-size: 12px;
  margin-bottom: 5px;
  padding: 0;

  img {
    padding: 0;
    margin: 0;
    width: 50%;
  }
}

.status-row-large {
  font-weight: bold;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding-bottom: 0.5rem;
}

.status-ticket-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.date {
  font-weight: bold;
  margin-bottom: 102px;
}

.team-info {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .seat-heating-container {
    padding: 10px;
  }

  .card {
    max-width: 100%;
    padding: 15px;
  }

  .stadium-image {
    height: 210px;
  }

  .match-info-banner {
    width: 95%;
    padding: 10px;
  }
}
</style>
