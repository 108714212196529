import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/dresden.jpg'


const _hoisted_1 = { class: "seat-heating-container" }
const _hoisted_2 = { class: "stadium-image-container" }
const _hoisted_3 = { class: "match-info-banner" }
const _hoisted_4 = { class: "status-ticket-container" }
const _hoisted_5 = { class: "status-ticket" }
const _hoisted_6 = { class: "status-row" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "team-info" }
const _hoisted_9 = { class: "status-row" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "team-info" }
const _hoisted_12 = { class: "date" }
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = { class: "all" }

import axios, { AxiosError, AxiosResponse } from 'axios';
import { onBeforeMount, onMounted, ref } from 'vue';
import { notifyUser } from '@/utils/helper';
import moment from 'moment';
import { useRouter } from 'vue-router';

/* interfaces */
import { tableInfo } from '@/interfaces/tableInfo';
import { nextMatch } from '@/interfaces/nextMatch';
import { Seats } from '@/interfaces/SeatData';

/** DATA */

export default /*@__PURE__*/_defineComponent({
  __name: 'DemoView',
  setup(__props) {

const activeSeats = ref<number>(0);
const seats = ref<number>();
const all = ref<boolean>(false);
const allSeats = ref<Seats[]>([]);
const load = ref<boolean>(false);
const disable = ref<boolean>(false);
const router = useRouter();
const table = ref<number>();
const tableOpponent = ref<number>();
const teamImage = ref<string>();
const teamImageOpponent = ref<string>();
const opponentName = ref<string>();
const gameDate = ref<string>();

/** METHODS */

const getTable = async (): Promise<void> => {
  await axios
    .get('https://api.openligadb.de/getbltable/bl3/2024')
    .then((res: AxiosResponse): void => {
      const response: tableInfo[] = res.data;

      response.forEach((row: tableInfo, index: number): void => {
        if (row.teamName === 'SG Dynamo Dresden') {
          table.value = index + 1;
        }

        if (row.teamName === opponentName.value) {
          tableOpponent.value = index + 1;
        }
      });
    })
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser('Error', 'Error while retrieving match data', 'danger');
    });
};

const getNextMatch = async (): Promise<void> => {
  await axios
    .get('https://api.openligadb.de/getnextmatchbyleagueteam/4756/177')
    .then((res: AxiosResponse): void => {
      const response: nextMatch = res.data;
      //? because searchedHome could be team1 | team2 in response
      if (response.team1.shortName === process.env.HOME_TEAM) {
        teamImage.value = response.team1.teamIconUrl;
        opponentName.value = response.team2.teamName;
        teamImageOpponent.value = response.team2.teamIconUrl;
      } else {
        teamImage.value = response.team2.teamIconUrl;
        opponentName.value = response.team1.teamName;
        teamImageOpponent.value = response.team1.teamIconUrl;
      }
    })
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser('Error', 'Error while retrieving match data', 'danger');
    });
};

const switchAll = async (): Promise<void> => {
  load.value = true;
  disable.value = true;
  allSeats.value.forEach((seat) => {
    seat.switch = true;
    seat.disabled = true;
  });
  if (!all.value) {
    allSeats.value.forEach((seat) => {
      seat.switch = false;
      seat.disabled = false;
      seat.loading = false;
    });
  }
  const token = localStorage.getItem('access_token');
  await axios
    .post(
      '/api/dali/all',
      {
        switchable: all.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((): void => {
      load.value = false;
      disable.value = false;
      notifyUser('Successfully', 'Chairs successfully switched', 'success');
    })
    .catch((e: AxiosError): void => {
      load.value = false;
      disable.value = false;
      allSeats.value.forEach((seat) => {
        seat.switch = false;
        seat.disabled = false;
      });
      console.error(e);
      all.value = false;
      if (e.response?.status === 401) {
        router.push({ name: 'login' });
      } else {
        load.value = false;
        notifyUser(
          'Something went wrong',
          'Error while switch the chairs',
          'danger'
        );
      }
    });
};

const switchOne = async (seat: number): Promise<void> => {
  allSeats.value[seat]['loading'] = true;
  allSeats.value[seat]['disabled'] = true;
  activeSeats.value = 0;

  allSeats.value.forEach((seat) => {
    if (seat.switch) activeSeats.value++;
  });

  if (activeSeats.value === seats.value) all.value = true;
  if (all.value && !allSeats.value[seat]['switch']) {
    all.value = false;
  }

  const token = localStorage.getItem('access_token');
  await axios
    .post(
      `/api/dali/device`,
      {
        switchable: allSeats.value[seat]['switch'],
        deviceId: allSeats.value[seat]['seatId'],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .catch((e: AxiosError): void => {
      if (process.env.NODE_ENV === 'local') console.error(e);
      notifyUser(
        'Something went wrong',
        'Error while switch the chair',
        'danger'
      );
      allSeats.value[seat]['loading'] = false;
      allSeats.value[seat]['disabled'] = false;
      allSeats.value[seat]['switch'] = false;
      if (all.value && !allSeats.value[seat]['switch']) {
        all.value = false;
      }
    });
};

onBeforeMount((): void => {
  seats.value = Number(process.env.VUE_APP_SEAT_AMOUNT);
  for (let i = 0; i < seats.value; i++) {
    allSeats.value.push({
      seatId: i + 1,
      name: 'Sitz ' + i + 1,
      loading: false,
      disabled: false,
      switch: false,
    });
  }
});

/* HOOKS */
onMounted(async (): Promise<void> => {
  await getNextMatch();
  await getTable();
});

return (_ctx: any,_cache: any) => {
  const _component_VaSwitch = _resolveComponent("VaSwitch")!
  const _component_VaCard = _resolveComponent("VaCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VaCard, { class: "card" }, {
      default: _withCtx(() => [
        _cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "header",
          style: {"justify-content":"center"}
        }, [
          _createElementVNode("h2", null, "Sitzheizung aktivieren")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "stadium-image",
            src: _imports_0,
            alt: "Stadion Bild"
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("h6", null, "Nächstes Spiel", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: teamImage.value,
                    alt: "dresden"
                  }, null, 8, _hoisted_7),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", null, "SG Dynamo Dresden", -1)),
                    _createElementVNode("span", null, _toDisplayString(table.value) + ". in der 3. Liga", 1)
                  ])
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "status-row-large" }, [
                  _createElementVNode("span", null, "VS")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    src: teamImageOpponent.value,
                    alt: opponentName.value
                  }, null, 8, _hoisted_10),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", null, _toDisplayString(opponentName.value), 1),
                    _createElementVNode("span", null, _toDisplayString(tableOpponent.value) + ". in der 3. Liga", 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(moment)().format('DD.MM.YYYY')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Alle aktivieren", -1)),
            _createVNode(_component_VaSwitch, {
              modelValue: all.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((all).value = $event)),
              loading: load.value,
              color: "#F3AC26",
              disabled: disable.value,
              size: "small",
              onInput: switchAll
            }, null, 8, ["modelValue", "loading", "disabled"])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allSeats.value, (seat, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "single"
            }, [
              _createElementVNode("h2", null, "Sitzplatz " + _toDisplayString(index + 1) + " aktivieren", 1),
              _createVNode(_component_VaSwitch, {
                modelValue: seat.switch,
                "onUpdate:modelValue": ($event: any) => ((seat.switch) = $event),
                loading: seat.loading,
                color: "#F3AC26",
                disabled: seat.disabled,
                size: "small",
                onInput: ($event: any) => (switchOne(index))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "loading", "disabled", "onInput"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})