import { createApp } from 'vue';
import { createVuestic } from 'vuestic-ui';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vuestic-ui/css';
import VueCountdown from '@chenfengyuan/vue-countdown';
import axios from 'axios';

const app = createApp(App);

axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_UR}`,
});

app.use(router);
app.use(store);
app.use(createVuestic());

app.component(<string>VueCountdown.name, VueCountdown);

app.mount('#app');
