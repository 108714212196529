import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import DemoView from '../views/DemoView.vue';
import DemoAccess from '../views/DemoAccess.vue';
import { isAuthenticated } from './router-guard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: DemoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: DemoAccess,
  },
  /*
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: 'mandant',
  },
    children: [
      {
        path: ':mandant',
        name: 'mandant',
        component: HomeView,
        children: [
          {
            path: ':seatId/:blockId?/:rowId?',
            name: 'seatId',
            component: HomeView,
          },
        ],
      },
    ],
  },
  {
    path: '/completed/:id',
    name: 'id',
    component: HomeView,
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: FailedPayment,
  },
  {
    path: '/select',
    name: 'select',
    component: SelectContextView,
  },
  */
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authenticated = await isAuthenticated();

  if (to.meta.requiresAuth && !authenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

/*
router.beforeEach((to, from, next) => {
  const validMandants = ['demo', 'completed'];
  const mandant = to.params.mandant;

  if (mandant && validMandants.includes(<string>mandant)) {
    next();
  } else if (!mandant) {
    next();
  } else {
    next({ path: '/demo' });
  }
});
*/

export default router;
