import { useToast } from 'vuestic-ui';

const { notify } = useToast();

export function notifyUser(
  title: string,
  message: string,
  color: string,
  closeable = false
): void {
  notify({
    title,
    message,
    color,
    closeable,
  });
}
