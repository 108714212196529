import axios from 'axios';

export const isAuthenticated = async (): Promise<boolean> => {
  const token = localStorage.getItem('access_token');
  if (token) {
    try {
      const res = await axios.get('/api/auth/authorized', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};
