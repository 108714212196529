import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "seat-heating-container" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "input" }

import axios, { AxiosError, AxiosResponse } from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { notifyUser } from '@/utils/helper';

/** DATA */

export default /*@__PURE__*/_defineComponent({
  __name: 'DemoAccess',
  setup(__props) {

const pin = ref<string>();
const router = useRouter();

/** METHODS */
const authenticate = async (): Promise<void> => {
  await axios
    .post('/api/auth/login', {
      pin: pin.value,
    })
    .then((res: AxiosResponse): void => {
      if (res.status === 201) {
        localStorage.setItem('access_token', res.data.access_token);
        router.push({ name: 'home' });
      } else {
        notifyUser('Error', 'Error while authenticating', 'danger');
      }
    })
    .catch((e: AxiosError): void => {
      console.error(e);
      notifyUser('Error', 'Error while authenticating', 'danger');
    });
};

/** HOOKS */

return (_ctx: any,_cache: any) => {
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaCard = _resolveComponent("VaCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VaCard, { class: "card" }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "heading" }, [
          _createElementVNode("h1", null, "Please enter your PIN")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_VaInput, {
            modelValue: pin.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((pin).value = $event)),
            placeholder: "PIN",
            inputmode: "number",
            color: "#F3AC26",
            style: {"width":"100%"},
            type: "tel"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_VaButton, {
            color: "#F3AC26",
            style: {"width":"100%"},
            onClick: authenticate
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Confirm")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})