<script setup lang="ts">
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { notifyUser } from '@/utils/helper';

/** DATA */
const pin = ref<string>();
const router = useRouter();

/** METHODS */
const authenticate = async (): Promise<void> => {
  await axios
    .post('/api/auth/login', {
      pin: pin.value,
    })
    .then((res: AxiosResponse): void => {
      if (res.status === 201) {
        localStorage.setItem('access_token', res.data.access_token);
        router.push({ name: 'home' });
      } else {
        notifyUser('Error', 'Error while authenticating', 'danger');
      }
    })
    .catch((e: AxiosError): void => {
      console.error(e);
      notifyUser('Error', 'Error while authenticating', 'danger');
    });
};

/** HOOKS */
</script>

<template>
  <div class="seat-heating-container">
    <VaCard class="card">
      <div class="heading">
        <h1>Please enter your PIN</h1>
      </div>
      <div class="input">
        <VaInput
          v-model="pin"
          placeholder="PIN"
          inputmode="number"
          color="#F3AC26"
          style="width: 100%"
          type="tel"
        />
      </div>
      <div class="input">
        <VaButton color="#F3AC26" style="width: 100%" @click="authenticate"
          >Confirm</VaButton
        >
      </div>
    </VaCard>
  </div>
</template>

<style lang="scss" scoped>
.seat-heating-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  gap: 1rem;
  height: 200px;
}

.heading {
  display: flex;
  justify-content: center;
}

.input {
}
</style>
